'use strict';

export function HeaderEffect({selector}) {
  const headerElement = document.querySelector(selector);
  if(headerElement) {
    window.addEventListener('scroll', () => {
      if(window.scrollY === 0) {
        headerElement.classList.add('l-header-effect--transparent');
      } else {
        headerElement.classList.remove('l-header-effect--transparent');
      }
    });
  }
}