'use strict';

export function TabMenu({tab, labels, panels, attribute}) {
  const tabElement = document.querySelector(tab);
  const tabLabels = document.querySelectorAll(labels);
  const tabPanels = document.querySelectorAll(panels);
  if(tabElement) {
    tabLabels.forEach((label) => {
      label.addEventListener('click', (event) => {
        const currentTabIndex = event.target.getAttribute(attribute);
        const currentTabPanel = Array.from(tabPanels).find((panel) => panel.getAttribute(attribute) === currentTabIndex);
        const currentTabLabel = Array.from(tabLabels).find((label) => label.getAttribute(attribute) === currentTabIndex);
        tabPanels.forEach((panel) => {
          panel.classList.remove('__tab-panel--active');
        });
        tabLabels.forEach((label) => {
          label.classList.remove('__tab-label--active');
        });
        currentTabPanel.classList.add('__tab-panel--active');
        currentTabLabel.classList.add('__tab-label--active');
      });
    })
  }
}