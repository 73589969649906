'use strict';

export function DrawerNavigation({selector, trigger, close}) {
  document.querySelector(trigger).addEventListener('click', () => {
    document.querySelector(selector).classList.add('__open');
  });
  document.querySelector(close).addEventListener('click', () => {
    document.querySelector(selector).classList.remove('__open');
  });
  window.addEventListener('resize', () => {
    if(window.matchMedia('(min-width: 1081px)').matches) {
      document.querySelector(selector).classList.remove('__open');
    }
  });
}