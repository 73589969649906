<script>
  export default {
    data() {
      return {
        slideMainVisual: {
          currentSlide: 1,
          slides: 3
        },
        slideFutures: {
          currentSlide: 1,
          slides: 3
        },
        currentSelectArea: '',
        checkParentChange: false,
        selected3: '',
      }
    },
    computed: {
      positionSlideFutures() {
        return { transform: `translateX(${(this.slideFutures.currentSlide - 1) * -100}%)`}
      }
    },
    created() {
      this.runSlideMainVisual(5000);
      this.runSlideFutures(5000);
    },
    mounted() {
      if(this.isAreaQuery()) {
        window.addEventListener('pageshow',()=>{
          if(window.performance.navigation.type==2) location.reload();
        });
      }
      this.changeChildAreaSelectOnLoad();
    },
    methods: {
      runSlideMainVisual(duration){
        setInterval(() => {
          if(this.slideMainVisual.currentSlide < this.slideMainVisual.slides) {
            this.slideMainVisual.currentSlide++;
          } else {
            this.slideMainVisual.currentSlide = 1;
          }
        }, duration);
      },
      runSlideFutures(duration){
        setInterval(() => {
          if(this.slideFutures.currentSlide < this.slideFutures.slides) {
            this.slideFutures.currentSlide++;
          } else {
            this.slideFutures.currentSlide = 1;
          }
        }, duration);
      },
      pagerSlideFutures(index){
        this.slideFutures.currentSlide = index;
      },
      changeChildAreaSelect(event) {
        // this.currentSelectArea = event.target.value;
        if (event.target.value != "") {
          this.currentSelectArea = event.target.value;
          this.checkParentChange = true;
          this.selected3 = '';
        } else {
          this.currentSelectArea = ""   
          this.checkParentChange = true;
        }
      },
      changeChildAreaSelectOnLoad() {
        if(this.$refs.area_select_parent) {
          this.currentSelectArea = this.$refs.area_select_parent.getAttribute('data-area');
        }else {
       
          if (document.getElementsByClassName("js-area-select-parent")[0] != null) {
            const parentSelectTag = document.getElementsByClassName("js-area-select-parent")[0];
            const parentSelectNum = parentSelectTag.selectedIndex;
            const parentSelectHref = parentSelectTag.options[parentSelectNum].value;
            this.currentSelectArea = parentSelectHref
          }

        }
      },
      isAreaQuery() {
        const currentUrl = new URL(window.location.href);
        return currentUrl.searchParams.get('area') !== null ? true : false;
      }
    }
  }

</script>

