'use strict';

// stylesheet import
import '../sass/main.sass';

// script modules import
import Vue from 'vue';
import Swiper from 'swiper/bundle';
import 'swiper/css/bundle';
import App from './vue/app.vue';
import { IntersectionObserverToggleClass } from './modules/class-intersection-observer-toggleclass.js';
import { IsLegacyIE } from './modules/checking-legacy-ie.js';
import { FindOS } from './modules/checking-os.js';
import { FindBrowser } from './modules/checking-browser.js';
import { ScrollTop } from './modules/scroll-top.js';
import { DeleteParentElement } from './modules/delete-parent-element.js';
import { DrawerNavigation } from './modules/drawer-navigation.js';
import { TabMenu } from './modules/tab-menu.js';
import { HeaderEffect } from './modules/header-effect.js';
import { DigitCommaSeparate } from './modules/digit-comma-separate.js';

// Vue.js
new Vue(App).$mount('#app');

// jQuery
const $ = require('jQuery');
$(function() {
  console.log('jQuery is ready.'); // eslint-disable-line no-console
});


const mySwiper = new Swiper ('.top-swiper', {
  // 以下にオプションを設定
  loop: true,
  autoplay: {
    delay: 6000,
    disableOnInteraction: false,
  },
  pagination: { 
    el: '.swiper-pagination', //ページネーションの要素
    type: 'bullets', //ページネーションの種類
    clickable: true, //クリックに反応させる
  },
  navigation: { 
    nextEl: '.swiper-button-next', //「次へボタン」要素の指定
    prevEl: '.swiper-button-prev', //「前へボタン」要素の指定
  },
});

// swiper
const swiperSlideThumbnail = new Swiper('.js-swiper-slide-thumbnail', {
  spaceBetween: 10,
  slidesPerView: 4,
  watchSlidesVisibility: true,
  watchSlidesProgress: true,
});
const swiperSlideMain = new Swiper('.js-swiper-slide-main', {
  spaceBetween: 10,
  navigation: {
    nextEl: '.js-swiper-slide-main-next',
    prevEl: '.js-swiper-slide-main-prev',
  },
  thumbs: {
    swiper: swiperSlideThumbnail,
  },
});

// Intersection Observer
new IntersectionObserverToggleClass({
  selector: '.observe_target', 
  className: 'observed', 
  toggle: true
});

// checking legacy IE
IsLegacyIE();

// checking OS
FindOS();

// checking browser
FindBrowser();

// scroll top
ScrollTop({
  selector: '.js-scroll-top'
});

// delete parent element
DeleteParentElement({
  selectors: '.js-delete-parent-element'
});

// drawer navigation
DrawerNavigation({
  selector: '.js-drawer-navi', 
  trigger: '.js-hamburger', 
  close: '.js-drawer-close'
});

// tab menu
TabMenu({
  tab:'.js-tab', 
  labels: '.js-tab-trigger', 
  panels: '.js-tab-target',
  attribute: 'data-tab'
});

// header effect
HeaderEffect({
  selector: '.js-header-effect'
});

// digit comma separate
DigitCommaSeparate({
  selector: '.js-digit-comma-separate'
});

// window.onload = function onLoad() {
//   var param = GetQueryString();

//   var target = document.getElementById("app");
//   if (param == null || param["area"] == undefined) {
//    // target.innerHTML = "パラメーターはありません。";
//     console.log('aaaaa');
//   } else {
//    // target.innerHTML = "パラメーターがあります";
//     target.innerHTML = param["area"];
//   }

//   var target = document.getElementById("param_mode_out");
//   if (param == null || param["mode"] == undefined) {
//     //target.innerHTML = "パラメーターはありません。";
//     console.log('aaaaa');
//   }
//   else {
//     //target.innerHTML = param["mode"];
//   }
// }

// function GetQueryString() {
//     if (1 < document.location.search.length) {
//         var query = document.location.search.substring(1);
//         var parameters = query.split('&');

//         var result = new Object();
//         for (var i = 0; i < parameters.length; i++) {
//             var element = parameters[i].split('=');

//             var paramName = decodeURIComponent(element[0]);
//             var paramValue = decodeURIComponent(element[1]);

//             result[paramName] = decodeURIComponent(paramValue);
//         }
//         return result;
//     }
//     return null;
// }